<template>
  <div class="asr">
    <div class="asr-container">

      <div class="asr-wrap">
        <div class="asr-block-form">
          <div class="asr-form-create">
            <div class="row asr-block-title">
              <div class="col-md-12">
                <h2 class="asr-form-title font-header">{{ 'នាយកដ្ឋានសំណង់នៅកម្ពុជា' || appName }}</h2>
                <p class="asr-form-sub-title">{{ $t('string.welcome_back') }}</p>
              </div>

              <div class="col-md-12" v-if="isLoginFail">
                <div class="alert alert-danger alert-dismissible" role="alert">
                  <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <p>{{ messageLoginFail }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group">
                <input :placeholder="$t('label.email')"
                       class="form-control"
                       type="email"
                       :class="{'has-error' : validations.hasOwnProperty('email')}"
                       v-model="credentials.email">
                <template v-if="validations.hasOwnProperty('email')">
                  <span class="label-error has-error">
                    {{ validations['email'][0] }}
                  </span>
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 form-group">
                <input :placeholder="$t('label.password')"
                       class="form-control"
                       :class="{'has-error' : validations.hasOwnProperty('password')}"
                       type="password"
                       v-model="credentials.password">
                <template v-if="validations.hasOwnProperty('password')">
                  <span class="label-error has-error">
                    {{ validations['password'][0] }}
                  </span>
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 margin-bottom">
                <router-link :to="{name: 'home'}">
                  {{ $t('btn.back') }}
                </router-link>
              </div>
              <!--<div class="col-md-6">
                <a href="javascript:void(0)" class="forget-password">{{ $t('label.forget_password') }}</a>
              </div>-->
            </div>
            <div class="row">
              <div class="col-md-12 item-center mt-4 mb-2 margin-bottom">
                <button class="btn btn-primary btn-block capitalize btn-login slide"
                        @click="onClickLogin">
                  {{ $t('btn.login') }}
                </button>
              </div>
            </div>
            <div class="row" v-if="facebook_enable">
              <div class="col-md-12 item-center mt-2 mb-4">
                <facebook-login-button></facebook-login-button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p class="text-center">
                  © {{ (new Date).getFullYear() }}.
                  {{ $t('string.right_reserved') }} <strong>អគ្គនាយកដ្ឋានសំណង់</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="asr-block-img"></div>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookLoginButton from '@/components/FacebookLoginButton'

export default {
  name: 'Login',
  metaInfo () {
    return {
      title: this.$t('page.login'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { FacebookLoginButton },
  data () {
    return {
      facebook_enable: false,
      appName: process.env.VUE_APP_NAME,
      credentials: {
        email: '',
        password: ''
      },
      validations: {},
      isLoginFail: false,
      messageLoginFail: '',
      config: process.env
    }
  },
  methods: {
    onClickLogin (e) {
      const self = this
      self.$isLoading(true)
      self.isLoginFail = false
      self.messageLoginFail = ''
      self.validations = {}
      self.$axios.post(process.env.VUE_APP_API + '/api/gdc/auth/login', self.credentials)
        .then((response) => {
          if (response.data && response.data.data) {
            const result = response.data.data
            const token = result.access_token
            const refresh = result.refresh_token

            localStorage.setItem(this.$token, token)
            localStorage.setItem(this.$refresh_token, refresh)

            self.$axios.defaults.headers.common.Authorization = 'Bearer ' + token
            self.$axios.defaults.headers.common.Accept = 'application/json'
            self.$store.dispatch('user/checkUserLogin', true)
            self.$store.dispatch('user/setUser', { user: result.user })

            // store roles
            self.$store.commit('user/setRoles', result.roles)

            // store permission
            self.$store.commit('user/setPermissions', result.permissions)

            self.$router.push({
              name: 'admin',
              replace: true
            })
          }
        })
        .catch((error) => {
          this.validations = {}
          if (error.response && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.isLoginFail = true
            const message = error.response.data.message
            this.messageLoginFail = message['message_' + this.$i18n.locale]
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    onPressEnter (e) {
      if (e.key === 'Enter') {
        this.onClickLogin()
      }
    }
  },
  mounted () {
    window.addEventListener('keypress', this.onPressEnter)
  },
  destroyed () {
    window.removeEventListener('keypress', this.onPressEnter)
  }
}
</script>

<style scoped>
@import "./../../assets/css/login.css";

.alert {
  margin-bottom: unset;
}
</style>
