<template>
  <button class="btn btn-facebook btn-block"
          @click="checkLoginState">
    <i class="fab fa-facebook-square icon"></i>
    {{$t('label.loginWithFacebook')}}
  </button>
</template>

<script>
export default {
  name: 'FacebookLoginButton',
  props: {
    method: {
      type: Function
    }
  },
  methods: {
    resultCallback (access_token) {
      console.log('token', access_token)
      const self = this
      self.$isLoading(true)
      this.$axios.post(this.$base_api + '/api/auth/frontend/login-with-facebook', {
        access_token: access_token
      }).then((response) => {
        if (response.data.code === 1) {
          const result = response.data.data
          const token = result.access_token
          const refresh = result.refresh_token

          localStorage.setItem(this.$token, token)
          localStorage.setItem(this.$refresh_token, refresh)

          self.$axios.defaults.headers.common.Authorization = 'Bearer ' + token
          self.$axios.defaults.headers.common.Accept = 'application/json'
          self.$store.dispatch('user/checkUserLogin', true)
          self.$store.dispatch('user/setUser', { user: result.user })

          // store roles
          self.$store.commit('user/setRoles', result.roles)

          // store permission
          self.$store.commit('user/setPermissions', result.permissions)

          self.$router.push({
            name: 'home',
            replace: true
          })
        }
        if (response.data.code === 0) {
          self.validations = response.data.message
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          self.isLoginFail = true
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },

    loginWithFB () {
      const self = this
      FB.login(function (response) {
        if (response && response.status === 'connected') { // Logged into your webpage and Facebook.
          console.log('status', response.status)
          self.resultCallback(response.authResponse.accessToken)
        }
      }, { scope: 'email' })
    },
    statusChangeCallback (response) { // Called with the results from FB.getLoginStatus().
      // The current login status of the person.
      if (response && response.status === 'connected') { // Logged into your webpage and Facebook.
        this.resultCallback(response.authResponse.accessToken)
      } else {
        this.loginWithFB()
      }
    },
    checkLoginState () {
      const self = this
      // Called when a person is finished with the Login Button.
      FB.getLoginStatus(function (response) { // See the on login handler
        self.statusChangeCallback(response)
      })
    }
  }
}
</script>

<style scoped>
  .btn-facebook {
    color: white;
    background-color: #4267b2;
    height: auto;
    min-height: 44px;
  }

  .btn-facebook .icon {
    font-size: 24px;
  }

  .btn-facebook:hover,
  .btn-facebook:focus {
    color: white;
    background-color: #395293;
  }

</style>
